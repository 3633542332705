<script>
import { dataLayerEvent } from '@utils/data-layer.js';
import Carousel from '@components/elements/carousel.vue';

export default {
    name: "MainScreenPages",
    props: {
        title: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        anchor: {
            type: String,
            default: "main-screen"
        },
        buttonText: {
            type: String,
            default: "Попробовать"
        },
        buttonTextTranslit: {
            type: String,
            default: 'poprobovat'
        },
        buttonLinkToGeoeffect: {
            type: Boolean,
            default: false
        },
        slides: {
            type: Array,
            default: () => []
        },
    },
    components: {
        Carousel,
    },
    data() {
        return {};
    },
    computed: {
        sliderConfig() {
            const desktopSpace = this.slides.length > 3 ? 90 : 140;

            return {
                desktop: {
                    w: 492,
                    h: 307,
                    space: desktopSpace,
                },
                mobile: {
                    w: 295,
                    h: 184,
                    space: 80,
                },
            };
        }
    },
    methods: {
        dataLayerEventClick() {
            event.preventDefault();
            dataLayerEvent({
                eventId: 6,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: this.buttonTextTranslit,
                eventAction: 'button_click'
            });

            const target = event.target.closest('#main-screen-pages-btn');
            if(target) {
                const loader = document.getElementById('preloading-spinner-wrap');
                loader.style = 'display: flex;';

                const send = XMLHttpRequest.prototype.send;
                XMLHttpRequest.prototype.send = function() {
                    this.addEventListener('readystatechange', function() {
                        // тут добавляем сравнение текущего урла  this.responseURL, чтобы обрабатывались только запросы, которые начинаются на такую строку
                        if (this.readyState === 4 && this.responseURL.startsWith('https://api.a.mts.ru')) {
                            window.location.href = target.href;
                        }
                    }, false);
                    send.apply(this, arguments);
                };
            }
        }
    }
}
</script>

<template>
    <div :id="anchor" class="main-screen animate-item">
        <div class="main-screen-content main-screen-content-pages">
            <div
                class="main-screen-title main-screen-pages-title"
                :class="{ 'main-screen-title-long': title.length > 24 }"
            >{{ title }}</div>
            <div class="main-screen-info">
                <div class="main-screen-info-text">
                    <div
                        v-html="text"
                        class="main-screen-info-text-description"
                    ></div>
                    <div class="main-screen-button main-screen-button-desktop">
                        <a
                            v-if="buttonLinkToGeoeffect"
                            id="main-screen-pages-btn"
                            class="btn"
                            href="https://system.geoeffect.ru/geoeffect_promo"
                            @click="dataLayerEventClick"
                        >
                            <button class="buttonDH">
                                <div class="buttonContentDH">
                                    {{ buttonText }}
                                </div>
                            </button>
                        </a>
                        <div
                            v-else
                            class="btn"
                            data-product="GeoEffect"
                            data-vitrine="Геоэффект"
                            data-btn-view="hidden"
                            data-bo-hw-lead-button="true"
                            data-bo-required-inn="true"
                            data-bo-not-required="email"
                            data-bo-subline=""
                            :data-btn-text="buttonText"
                            @click="dataLayerEventClick"
                        >
                            <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
                        </div>
                    </div>
                </div>
                <div class="main-screen-info-slider">
                    <div class="main-screen-info-slider-desktop">
                        <Carousel
                            :slide-images="slides"
                            :width="sliderConfig.desktop.w"
                            :height="sliderConfig.desktop.h"
                            :space="sliderConfig.desktop.space"
                            :autoplay-time="3000"
                        />
                    </div>
                    <div class="main-screen-info-slider-mobile">
                        <Carousel
                            :slide-images="slides"
                            :width="sliderConfig.mobile.w"
                            :height="sliderConfig.mobile.h"
                            :space="sliderConfig.mobile.space"
                            :autoplay-time="3000"
                            :no-mobile-carousel="true"
                        />
                    </div>
                </div>
                <div class="main-screen-button main-screen-button-mobile">
                    <a
                        v-if="buttonLinkToGeoeffect"
                        class="btn"
                        target="_blank"
                        href="https://system.geoeffect.ru/geoeffect_promo"
                        @click="dataLayerEventClick"
                    >
                        <button class="buttonDH">
                            <div class="buttonContentDH">
                                {{ buttonText }}
                            </div>
                        </button>
                    </a>
                    <div
                        v-else
                        class="btn"
                        data-product="GeoEffect"
                        data-vitrine="Геоэффект"
                        data-btn-view="hidden"
                        data-bo-hw-lead-button="true"
                        data-bo-required-inn="true"
                        data-bo-not-required="email"
                        data-bo-subline=""
                        :data-btn-text="buttonText"
                        @click="dataLayerEventClick"
                    >
                        <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/main-screen.scss';
</style>