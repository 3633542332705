<script>
import { dataLayerEvent } from '@utils/data-layer.js';
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
    name: "Carousel",
    props: {
        slideImages: {
            type: Array,
            default: () => []
        },
        width: {
            type: Number,
            default: 640
        },
        height: {
            type: Number,
            default: 400
        },
        space: {
            type: Number,
            default: 200
        },
        noMobileCarousel: {
            type: Boolean,
            default: false
        },
        autoplayTime: {
            type: Number,
            default: null
        },
    },
    components: {
        Carousel3d,
        Slide
    },
    methods: {
        clickOnArrowEvent(label) {
            return () => {
                dataLayerEvent({
                    eventId: 7,
                    event: 'vntGeo',
                    eventCategory: 'slaider',
                    eventLabel: label,
                    eventAction: 'carousel_click'
                });
            };
        },
    },
    mounted() {
        const carousel = this.$refs['carousel'];
        carousel.getElementsByClassName('prev')[0].addEventListener('click', this.clickOnArrowEvent('nazad'));
        carousel.getElementsByClassName('next')[0].addEventListener('click', this.clickOnArrowEvent('vpered'));
    },
    beforeDestroy() {
        const carousel = this.$refs['carousel'];
        carousel.getElementsByClassName('prev')[0].removeEventListener('click', this.clickOnArrowEvent('nazad'));
        carousel.getElementsByClassName('next')[0].removeEventListener('click', this.clickOnArrowEvent('vpered'));
    }
}
</script>

<template>
    <div
        ref="carousel"
        class="business-slider"
    >
        <Carousel3d
            :class="{ 'business-slider-no-mobile': noMobileCarousel }"
            :width="width"
            :height="height"
            :controlsVisible="true"
            :perspective="0"
            :space="space"
            :autoplay="Boolean(autoplayTime)"
            :autoplayTimeout="autoplayTime"
            :clickable="true"
        >
            <slide
                v-for="(slideUrl, i) in slideImages"
                :key="i"
                :index="i"
            >
                <div
                    class="carousel-slider-image"
                    :style="`background-image: url('${slideUrl}')`"
                ></div>
            </slide>
        </Carousel3d>
        <div
            v-if="noMobileCarousel"
            class="business-slider-mobile"
        >
            <div class="business-slider-mobile-container">
                <div
                    v-for="(slideUrl, i) in slideImages"
                    :key="i"
                    class="business-slider-mobile-image"
                    :style="`background-image: url('${slideUrl}')`"
                ></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/elements/carousel.scss';
</style>